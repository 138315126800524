import {parse, isDate} from "date-fns";

export const formatAmount = (number: string|number) => { return (+number).toFixed(2) }

export const formatDate = (date: Date) => { return (""+date.getDate()).padStart(2, "0")+"/"+(""+(date.getMonth()+1)).padStart(2, "0")+"/"+date.getFullYear() };

// export const parseDate = (dateString: string) => {

//     const date = new Date();

//     const dateArr = dateString.split("/");

//     date.setDate(+dateArr[0]);
//     date.setMonth(+dateArr[1]-1);
//     date.setFullYear(+dateArr[2]);

//     console.log(date);

//     return date;
// }

export function parseDateStringYup(dateString: string) {

    if( !/^(0?[1-9]|[1-2][0-9]|3[01])[/](0?[1-9]|1[0-2])[/]\d{4}$/.test(dateString)){
        return dateString;
    }

  return isDate(dateString)
    ? dateString  // this make sure that a value is provided
    : parse(dateString, "dd/MM/yyyy", new Date());
}

export function parseDate(dateString: string){

  return parse(dateString, "dd/MM/yyyy", new Date());
}