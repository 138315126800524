export enum Page {
    Group = 1,
    Account = 2,
    Bank_Transaction = 3,
    Transaction = 4,
    GSTR2 = 5
}

export const PAGE_NAME_GROUP = "Group";
export const PAGE_NAME_ACCOUNT = "Account";
export const PAGE_NAME_BANK_TRNX = "Bank Transaction";
export const PAGE_NAME_TRNX = "Transaction";
export const PAGE_NAME_GSTR2 = "GSTR2";

export const pagesList = [
    { label: PAGE_NAME_GROUP, value: Page.Group  },
    { label: PAGE_NAME_ACCOUNT, value: Page.Account  },
    { label: PAGE_NAME_BANK_TRNX, value: Page.Bank_Transaction  },
    { label: PAGE_NAME_TRNX, value: Page.Transaction  },
    { label: PAGE_NAME_GSTR2, value: Page.GSTR2  }
]